<template>
  <PageSection
    v-for="section in page.template.sections"
    :key="section.id"
    :section="section"
  />
</template>

<script setup lang="ts">
const page = useSofiePage();
</script>
