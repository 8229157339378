<template>
  <Component
    :is="sectionComponent"
    :id="id"
    v-bind="sectionProps"
  />
</template>

<script setup lang="ts">
import slugify from 'slugify';
import type {Maybe} from '@graphql/graphql';
import {
  LazySectionAgenda,
  LazySectionBanner,
  LazySectionDividerCrossHeart,
  LazySectionDividerDiamond,
  LazySectionDonate,
  LazySectionHeaderDonation,
  LazySectionHeaderImage,
  LazySectionHeaderImageTextCard,
  LazySectionHeaderImageTextCardOverflow,
  LazySectionHeaderShowcase,
  LazySectionHeroLatestVideo,
  LazySectionInstagram,
  LazySectionLinkCards,
  LazySectionLinkIcons,
  LazySectionLinkImages,
  LazySectionMagazinesArchive,
  LazySectionMagazinesLatest,
  LazySectionMapCommunities,
  LazySectionMapCommunitiesFamily,
  LazySectionMapTenants,
  LazySectionMedia,
  LazySectionNews,
  LazySectionQuotes,
  LazySectionText,
  LazySectionTextImageBanner,
  LazySectionTextImageBasic,
  LazySectionTextImageIllustration,
  LazySectionTextImageOffset,
  LazySectionTextIntro,
} from '#components';
import type {
  SectionBackground,
  SectionBlock,
  SectionBlocks,
  SectionIllustration,
  SectionOrder,
  SectionProps,
  Section,
} from '~/types/section';

const props = defineProps<{
  section: Section,
}>();

const sectionComponent = computed(() => ({
  'divider-cross-heart': LazySectionDividerCrossHeart,
  'divider-diamond': LazySectionDividerDiamond,
  'header-donation': LazySectionHeaderDonation,
  'header-image': LazySectionHeaderImage,
  'header-image-text-card': LazySectionHeaderImageTextCard,
  'header-image-text-card-overflow': LazySectionHeaderImageTextCardOverflow,
  'header-latest-video': LazySectionHeroLatestVideo,
  'header-showcase': LazySectionHeaderShowcase,
  'link-cards': LazySectionLinkCards,
  'link-icons': LazySectionLinkIcons,
  'link-images': LazySectionLinkImages,
  'magazines-archive': LazySectionMagazinesArchive,
  'magazines-latest': LazySectionMagazinesLatest,
  'map-communities': LazySectionMapCommunities,
  'map-tenants': LazySectionMapTenants,
  'map-communities-family': LazySectionMapCommunitiesFamily,
  'text-image-banner': LazySectionTextImageBanner,
  'text-image-basic': LazySectionTextImageBasic,
  'text-image-illustration': LazySectionTextImageIllustration,
  'text-image-offset': LazySectionTextImageOffset,
  'text-intro': LazySectionTextIntro,
  agenda: LazySectionAgenda,
  banner: LazySectionBanner,
  donate: LazySectionDonate,
  instagram: LazySectionInstagram,
  media: LazySectionMedia,
  news: LazySectionNews,
  quotes: LazySectionQuotes,
  text: LazySectionText,
}[props.section.type]!));

const id = computed(() => props.section.title ? `section-${slugify(props.section.title).toLowerCase()}` : undefined);

const sectionProps = computed(() => {
  const blocks: SectionBlocks = props.section.blocks?.map(block => ({
    ...block,
    title: undefined,
    label: block.title,
    image: block.image,
    to: block.to === '#' ? undefined : block.to,
  } as SectionBlock)) ?? [];

  const url = props.section.section_url || props.section.read_more?.url;

  if (url && props.section.read_more_label) {
    blocks.push({
      label: props.section.read_more_label,
      id: 'read_more',
      to: url,
    });
  }

  return {
    background: (props.section.background ?? 'default') as SectionBackground,
    blocks,
    communities: props.section.communities,
    content: props.section.content ?? '',
    event_types: props.section.event_types,
    illustration: props.section.illustration as Maybe<SectionIllustration> | undefined,
    image: props.section.image,
    media: props.section.media,
    order: (props.section.order ?? 'image_text') as SectionOrder,
    title: props.section.title,
    titleEnabled: props.section.title_enabled ?? true,
    url: props.section.section_url,
    youtube_id: props.section.youtube_id,
  } satisfies SectionProps;
});
</script>
